import React from 'react';
import { useTranslation } from 'react-i18next';

function AboutUs() {
 const { t } = useTranslation();
 return (
  <div className="p-3 mx-auto max-w-screen-lg container">
   <p className="text-center">{t("about fishingfinity")}</p>
  </div>
 );
}

export default AboutUs
