import { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { useTranslation } from 'react-i18next';

import { db } from '../config/fb'
import PopUp from "../components/PopUp";


const Contact = () => {
  const [contactInfo, setContactInfo] = useState({
    email: '',
    subject: '',
    message: '',
    fullname: '',
  })
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const { email, message, subject, fullname } = contactInfo
  const { t } = useTranslation();

  const htmlDesign = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
  <html lang="en">
    <head></head>
    <body style="background-color:#ffffff;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,sans-serif">
      <table align="center" role="presentation" cellSpacing="0" cellPadding="0" border="0" width="100%" style="max-width:37.5em;margin:0 auto;padding:20px 25px 48px;background-image:url(&quot;/assets/raycast-bg.png&quot;);background-position:bottom;background-repeat:no-repeat, no-repeat">
        <tr style="width:100%">
          <td>
          <img alt="Raycast" src="https://firebasestorage.googleapis.com/v0/b/fishingfinity-bytycoonlures.appspot.com/o/FIAMImages%2F1024x1024.png?alt=media&token=06119958-7925-4ea0-aff4-a219da97a14e" width="48" height="48" style="display:block;outline:none;border:none;text-decoration:none" />
            <table style="margin:24px 0" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation" width="100%">
              <tbody>
                <tr>
                 <p style="font-size:14px">From: ${fullname} < ${email} > <a target="_blank" style="color:#15c;text-decoration:underline;font-size:12px;margin:0;padding:0;line-height:1.4"></a></p>
                  <p style="font-size:14px">Subject: ${subject}</p>
                  <td>
                    <p style="font-size:14px">Message body: </p>
                    <pre style="font-size:16px;line-height:26px">${message}</pre>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style="font-size:13px;line-height:24px;margin:0;color:#AFAFAF">---</p> 
             <p style="font-size:12px">This e-mail was sent from a contact form on Fishingfinity.(https://fishingfinity.com/) <a target="_blank" style="color:#15c;text-decoration:underline;font-size:10px;margin:0;padding:0;line-height:1.4"></a></p>
            <hr style="width:100%;border:none;border-top:1px solid #eaeaea;border-color:#dddddd;margin-top:20px" />
             <p style="font-size:13px;line-height:24px;margin:0;color:#AFAFAF;text-align:center">© 2022-2023 Fishingfinity. All Rights Reserved.</p>
            <p style="font-size:13px;line-height:24px;margin:0;color:#AFAFAF;text-align:center">ARBEST S.N.C di Reishei Aurellano.</p> 
          </td>
        </tr>
      </table>
    </body>
  </html>`


  const handeleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Mail"), {
        to: ['fishingfinityapp@gmail.com'],
        message: {
          subject,
          html: htmlDesign,
        },
      }).then(() => {
        setOpen(true)
        setContactInfo({
          email: '',
          subject: '',
          message: '',
          fullname: '',
        })
      })
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  return (
    <section className="flex justify-center">
      <div className="pt-5 pb-20 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center">{t("Contact Us")}</h2>
        <p className="mb-8 lg:mb-16 font-light text-center sm:text-xl">{t("Please fill in the information below to contact Fishingfinity.")}</p>
        <form action="#" className="space-y-8">
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium">{t("Your email")}</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={({ target }) => setContactInfo({ ...contactInfo, email: target.value })}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="name@example.com"
              required
            />
          </div>
          <div>
            <label htmlFor="fullname" className="block mb-2 text-sm font-medium">{t("Your Name")}</label>
            <input
              type='text'
              id="fullname"
              value={fullname}
              onChange={({ target }) => setContactInfo({ ...contactInfo, fullname: target.value })}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder={t("Your Name")}
              required
            />
          </div>
          <div>
            <label htmlFor="subject" className="block mb-2 text-sm font-medium">{t("Subject")}</label>
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={({ target }) => setContactInfo({ ...contactInfo, subject: target.value })}
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder={t("Let us know how we can help you")}
              required
            />
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block mb-2 text-sm font-medium">Your message</label>
            <textarea
              id="message"
              value={message}
              rows="6"
              onChange={({ target }) => setContactInfo({ ...contactInfo, message: target.value })}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder={t("Leave a comment") + "..."}
              required
            />
          </div>
          <button
            onClick={handeleSubmit}
            type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-600 w-full hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">{t("Send message")}</button>
        </form>

      </div>
      <PopUp open={open} closeModal={closeModal} />
    </section>
  );
};

export default Contact;