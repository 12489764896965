import React from 'react'
import { useTranslation } from 'react-i18next';

import backgroundTop from "../../images/tycoon.jpg";
import Gallery from '../../components/Gallery'
import DownloadBtn from '../../components/DownloadBtn';


export default function TopBanner() {
 const { t } = useTranslation();

 return (
  <div className="relative bg-neutral-600">
   <img
    src={backgroundTop}
    className='w-full h-full absolute mix-blend-overlay'
    alt='backgroundTop'
   />
   <div className="p-4 mx-auto max-w-screen-xl">
    <div className='flex-none md:flex items-center content-center justify-center'>
     <div className='text-center container lg:mr-10 md:mr-3'>
      <p className='font-black text-4xl'>{t("Sport Fishing App")}</p>
      <p className='font-bold text-md pt-3'>{t("Download the app")}</p>
      <DownloadBtn flexTo={false} />
     </div>
     <div>
      <Gallery />
     </div>
    </div>
   </div>
  </div>
 );
}
