import React, { useState } from "react";
import { Transition } from "@headlessui/react";

import logo from '../images/splash.png';
import MobileMenu from "./mobileMenu";
import MainMenu from "./mainMenu";
import DropDownMenu from "../components/DropDownMenu";

function NavBar() {
 const [isOpen, setIsOpen] = useState(false);
 return (
  <header>
   <nav className="p-2 border-b-2">
    <div className="grid grid-flow-col auto-cols-max justify-between items-center px-2">
     <div className="lg:hidden">
      <button
       onClick={() => setIsOpen(!isOpen)}
       type="button"
       className="inline-flex p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none"
       aria-controls="mobile-menu"
       aria-expanded="false"
      >
       {!isOpen ?
        <svg
         className="block h-6 w-6"
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         aria-hidden="true"
        >
         <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M4 6h16M4 12h16M4 18h16"
         />
        </svg>
        :
        <svg
         className="block h-6 w-6"
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
         aria-hidden="true"
        >
         <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
         />
        </svg>
       }
      </button>
     </div>
     <img
      className="h-12 w-20"
      src={logo}
      alt="App Logo"
     />
     <MainMenu />
     <DropDownMenu />
    </div>

    <Transition
     show={isOpen}
     enter="transition ease-out duration-100 transform"
     enterFrom="opacity-0 scale-95"
     enterTo="opacity-100 scale-100"
     leave="transition ease-in duration-75 transform"
     leaveFrom="opacity-100 scale-100"
     leaveTo="opacity-0 scale-95"
    >
     <MobileMenu />
    </Transition>
   </nav>
  </header>
 );
}

export default NavBar
