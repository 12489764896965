import React from 'react'
import Carousel from 'better-react-carousel'

import mkUp01 from "../images/mkUp01.png"
import mkUp02 from "../images/mkUp02.png"
import mkUp03 from "../images/mkUp03.png"
import mkUp04 from "../images/mkUp04.png"
import mkUp05 from "../images/mkUp05.png"

export default function Gallery() {

 const MyDot = ({ isActive }) => (
  <span
   style={{
    display: 'inline-block',
    height: isActive ? '10px' : '7px',
    width: isActive ? '10px' : '7px',
    background: '#2dff03',
    borderRadius: 10
   }}
  ></span>
 )

 return (
  <div className="flex-none md:flex justify-between items-center">
   <Carousel
    cols={3}
    rows={1}
    gap={3}
    showDots
    loop={true}
    autoPlay={2000}
    dot={MyDot}
    responsiveLayout={[
     {
      breakpoint: 2000,
      cols: 3,
      rows: 1,
      gap: 2,
      loop: true,
      autoplay: 2000
     },
     {
      breakpoint: 1000,
      cols: 2,
      rows: 1,
      gap: 2,
      loop: true,
      autoplay: 2000
     },
     {
      breakpoint: 500,
      cols: 1,
      rows: 1,
      gap: 2,
      loop: true,
      autoplay: 2000
     }
    ]}
    mobileBreakpoint={350}
    containerStyle={{ padding: 5, justify: 'center', }}
   >
    <Carousel.Item>
     <div className="flex justify-center items-center">
      <img src={mkUp04} alt='img' />
     </div>

    </Carousel.Item>
    <Carousel.Item>
     <div className="flex justify-center items-center">
      <img src={mkUp05} alt='img' />
     </div>

    </Carousel.Item>
    <Carousel.Item>
     <div className="flex justify-center items-center">
      <img src={mkUp01} alt='img' />
     </div>

    </Carousel.Item>
    <Carousel.Item>
     <div className="flex justify-center items-center">
      <img src={mkUp02} alt='img' />
     </div>

    </Carousel.Item>
    <Carousel.Item>
     <div className="flex justify-center items-center">
      <img src={mkUp03} alt='img' />
     </div>
    </Carousel.Item>
   </Carousel>
  </div>
 )
}