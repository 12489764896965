import { Outlet } from "react-router-dom";
import MainFooter from "../Footer";
import NavBar from "../Header/navBar";

const Root = () => {
 return (
  <div className="App bg">
   <div>
    <NavBar />
   </div>
   <main className="bg">
    <Outlet />
   </main>
   <div>
    <MainFooter />
   </div>
  </div>
 );
};

export default Root;