import * as React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const activeStyle = ({ isActive }) => {
 return {
  fontWeight: isActive ? "bold" : "",
  color: isActive ? "#a6ada8" : "white",
  fontSize: 17,
  textDecoration: isActive ? "underline" : "none",
 }
}

function MobileMenu() {
 const { t } = useTranslation();
 return (
  <div className="lg:hidden" id="mobile-menu">
   <div className="px-2 py-3 space-y-1 sm:px-3 text-left">
    <ul className="pl-3 pb-2">
     <NavLink to="/" style={activeStyle}>Home</NavLink>
    </ul>
    <ul className="pl-3 pb-2">
     <NavLink to="support" style={activeStyle}>{t("Support")}</NavLink>
    </ul>
    <ul className="pl-3 pb-2">
     <NavLink to="privacyAndPolicy" style={activeStyle}>{t("Privacy and Policy")}
     </NavLink>
    </ul>
    <ul className="pl-3 pb-2">
     <NavLink to="termsAndConditions" style={activeStyle}>
      {t("Terms and Conditions")}
     </NavLink>
    </ul>
    <ul className="pl-3 pb-2">
     <NavLink to="download-app" style={activeStyle}>
      {t("Download App")}
     </NavLink>
    </ul>

    {/* <ul className="pl-3">
     <NavLink
      to="https://partners-fishingfinity.web.app/"
      target="_blank"
      rel="noopener noreferrer"
      style={activeStyle}>
      Partnership
     </NavLink>
    </ul> */}
   </div>
  </div>
 )
}

export default MobileMenu
