import React from 'react'

import AboutUs from './AboutUs';
import TopBanner from './TopBanner';
import Features from './Features';
import SlideShow from './SlideShow';

function HomeScreen() {
 return (
  <div >
   <TopBanner />
   <AboutUs />
   <Features />
   <SlideShow />
  </div>
 )
}

export default HomeScreen
