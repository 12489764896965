import React from 'react'

function PartnerScreen() {
 return (
  <div className="mx-auto px-4">
   <header>
    <div className="mx-auto py-4 px-3">
     <h1 className="font-bold text-gray-900">Partners</h1>
    </div>
   </header>

   <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
    <div className="px-4 py-2 sm:px-0">
     <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">
     </div>
    </div>
   </div>

  </div>
 )
}

export default PartnerScreen
