import React from 'react'
import { Outlet } from 'react-router-dom'

function SupportScreen() {
 return (
  <div className="mx-auto px-4">
   <div className="mx-auto py-4 px-3">
   </div>
   <main>
    <Outlet />
   </main>
  </div>
 )
}

export default SupportScreen
